<template>
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">{{goalname}}</h5>
        </a-col>
        <a-col
          :span="24"
          :md="12"
          style="display: flex; align-items: center; justify-content: flex-end"
        >
        </a-col>
      </a-row>
    </template>
    <div class="card-title">
      <h6>Goal Details</h6>
    </div>
    <a-table
      :columns="eventcolumns"
      :data-source="eventlogs"
      :pagination="{ position: 'bottom', pageSize: 8 }"
     
    >
    </a-table>
    <div class="card-title">
      <h6>Actions List</h6>
    </div>
    <a-table
      :columns="actioncolumns"
      :data-source="actions"
      :pagination="{ position: 'bottom', pageSize: 8 }"
     
    >
      <template slot="actionSlot" slot-scope="row">
        <a-button
          icon="delete"
          shape="circle"
          @click="deleteaction(row)"
        ></a-button>
      </template>
    </a-table>
    <a-button class="fab" shape="circle" @click="addAction">
      <font-awesome-icon icon="plus" />
    </a-button>
  </a-card>
</template>
<script>
import { getitem, getdetail, removeactionfromgoal } from "@/api/goal";
import { getaction } from "@/api/action";

export default {
  props: {
    goalid: String,
  },
  data() {
    return {
      test: [],
      actions: [],
      eventlogs: [],
      goalname:[],
      eventcolumns: [
        { title: "Timestamp", dataIndex: "timestamp", width: 100 },
        { title: "ProductID", dataIndex: "productid", width: 100 },
        {
          title: "Result",
          dataIndex: "result",
          filters: [
            { text: "true", value: "true" },
            { text: "false", value: "false" },
          ],
		 onFilter: (value, record) => record.result.indexOf(value) === 0,
          width: 100,
        },
      ],
      actioncolumns: [
        { title: "Type", dataIndex: "actiontype", width: 100 },
        { title: "Name", dataIndex: "name" },
        { title: "Person in charge", dataIndex: "actionto" },
        {
          title: "",
          dataIndex: "actionid",
          width: 50,
          scopedSlots: { customRender: "actionSlot" },
        },
      ],
    };
  },
  methods: {
    // function: goal-08
    addAction() {
      this.$router.push({
        path: "/actionsselector",
        query: { goalid: this.goalid },
      });
    },
    // function: goal-09
    deleteaction(id) {
      removeactionfromgoal({ goalid: this.goalid, actionid: id }).then(
        (data) => {
          for (var i = 0; i < this.actions.length; i++)
            if (this.actions[i].actionid == id) this.actions.splice(i, 1);
        }
      );
    },
  },
  mounted() {
    var id = this.goalid;
    // function: goal-10
    getitem(id).then((data) => {
      this.goalname = data.name;
      if (!data.actionids) return;
      var actionid = data.actionids;
      for (var i = 0; i < actionid.length; i++) {
        getaction(actionid[i]).then((data) => {
          this.actions.push(data);
        });
      }
    });
    // function: goal-11
    getdetail(id).then((data) => {
      for (var i = 0; i < data.length; i++) {
		// if (i % 2 == 0){data[i].result='true'} // mock false and true situation, in real case, use the commented code instead
		// else {data[i].result='false'}
        console.log(data[i])
        if (data[i].result!=null){data[i].result = data[i].result.toString()};
        data[i]["timestamp"] = new Date(data[i]["timestamp"]).toString().substring(0,21);
      }
      this.eventlogs = data;
    });
  },
};
</script>
